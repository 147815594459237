import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "d-flex justify-content-end page-heading-toolbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BtnContent = _resolveComponent("BtnContent")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_PageMainHeading = _resolveComponent("PageMainHeading")!
  const _component_BrandsTable = _resolveComponent("BrandsTable")!
  const _component_AppTableController = _resolveComponent("AppTableController")!
  const _component_DeleteBrandDialog = _resolveComponent("DeleteBrandDialog")!
  const _component_EditBrandDialog = _resolveComponent("EditBrandDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PageMainHeading, { title: "Brands" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_AppButton, {
              type: "secondary",
              size: "lg",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editBrandDialogOpened = true))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BtnContent, {
                  icon: "plus",
                  label: "Add Brand"
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_AppTableController, {
      "show-table": _ctx.brandList.length,
      "empty-button-text": "Add Brands",
      "empty-key": "brand",
      "empty-icon": "brand",
      onEmptyButtonClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.createBrandDialogOpened = true))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BrandsTable, {
          "brands-list": _ctx.brandList,
          "total-rows-count": _ctx.totalBrandsCount,
          "initial-view-configuration": _ctx.viewConfiguration,
          sort: _ctx.sortConfig,
          onChangeViewConfiguration: _ctx.changeViewConfigurationHandler,
          onRemovingBrand: _ctx.removingBrand,
          onEditingBrand: _ctx.editingBrand
        }, null, 8, ["brands-list", "total-rows-count", "initial-view-configuration", "sort", "onChangeViewConfiguration", "onRemovingBrand", "onEditingBrand"])
      ]),
      _: 1
    }, 8, ["show-table"]),
    _createVNode(_component_DeleteBrandDialog, {
      opened: _ctx.deleteBrandDialogOpened,
      loading: _ctx.isLoading,
      "edit-data": _ctx.editData,
      "is-show": false,
      hide: false,
      onHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteBrandDialogOpened = false)),
      onConfirm: _ctx.onDeleteConfirm,
      onCancel: _ctx.onDeleteCancel
    }, null, 8, ["opened", "loading", "edit-data", "onConfirm", "onCancel"]),
    _createVNode(_component_EditBrandDialog, {
      title: _ctx.editText,
      loading: _ctx.isLoading,
      opened: _ctx.editBrandDialogOpened,
      "edit-data": _ctx.editData.id ? _ctx.editData : null,
      onHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.editBrandDialogOpened = false)),
      onSubmit: _ctx.submit
    }, null, 8, ["title", "loading", "opened", "edit-data", "onSubmit"])
  ], 64))
}