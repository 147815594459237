<template>
  <label :for="id" class="d-flex app-checkbox__wrapper">
    <span class="app-checkbox d-block">
      <input
        :id="id"
        v-model="localChecked"
        type="checkbox"
        v-bind="$attrs"
        hidden
        class="hidden-checkbox"
      />
      <span class="pseudo-checkbox">
        <SvgIcon icon="checkmark" />
      </span>
    </span>
    <span v-if="label" class="ms-3 app-checkbox__label">{{ label }}</span>
  </label>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon";
import { nextTick } from "vue";
export default {
  name: "AppCheckbox",
  components: { SvgIcon },
  inheritAttrs: false,
  inheritProps: false,
  props: {
    id: { type: String, required: true },
    model: { type: String, default: "" },
    label: { type: String, default: " " },
    modelValue: {
      type: Boolean,
      default: false
    },
    isDelay: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localChecked: this.modelValue
    };
  },
  watch: {
    modelValue(value) {
      if (value !== this.localChecked) {
        this.localChecked = value;
      }
    },
    localChecked(value) {
      if (value !== this.modelValue) {
        this.$nextTick(() => (this.localChecked = this.modelValue));
        this.$emit("update:modelValue", value);
      }
    }
  },
  methods: {
    checkHandler($event) {
      this.$emit("update:modelValue", $event.target.checked);
    },
    clickHandler($event) {
      if (this.isDelay) {
        $event.preventDefault();
        $event.stopPropagation();
      }
      this.$emit("update:modelValue", $event.target.checked);
    }
  }
};
</script>

<style scoped lang="scss">
.app-checkbox {
  --border-color: #{$border-color};
  input[type="checkbox"] {
    & + .pseudo-checkbox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.43em;
      height: 1.43em;
      min-width: 1.42em;
      min-height: 1.42em;
      border: 1px solid var(--border-color);
      border-radius: 0.5em;
      color: #fff;

      .icon {
        font-size: 0.63em;
        margin: 0;
      }
    }

    &:checked + {
      .pseudo-checkbox {
        background-color: $color-primary-blue;
        border-color: $color-primary-blue;
      }
    }
  }
  &__label {
    cursor: pointer;
  }

  &__wrapper {
    width: max-content;
    max-width: 100%;
    @media (any-hover: hover) {
      .pseudo-checkbox {
        transition: 0.2s;
      }

      &:hover {
        .app-checkbox {
          --border-color: #{$color-primary-blue};
        }
      }
    }
  }
}
</style>
