
import { useStore } from "vuex";
import { defineComponent, computed, watch, reactive } from "vue";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import BrandsTable, {
  brandsTableSort
} from "@/components/Table/ts/instances/brands/BrandsTable.vue";

import AppButton from "@/components/ui/Buttons/AppButton.vue";
import BtnContent from "@/components/ui/Buttons/BtnContent.vue";
import { brandsSetup } from "@/views/interfaces/brandsInterfaces";

import DeleteBrandDialog from "@/components/ui/Modal/Dialog/instances/DeleteBrandDialog.vue";
import EditBrandDialog from "@/components/ui/Modal/Dialog/instances/EditBrandDialog.vue";

import { IBrands } from "@/api/interfaces//brands/IBrands";
import AppTableController from "@/components/Table/ts/AppTableController.vue";
import { useSuportedDevices } from "@/hooks/devices/useDevices";
import { sortOrders } from "@/enums/main/sortOrders";
import { IBrand } from "@/store/interfaces/suported-device/IBrand";
interface brandInitialData {
  title: string;
  createBrandDialogOpened: boolean;
  deleteBrandDialogOpened: boolean;
  editBrandDialogOpened: boolean;
  isLoading: boolean;
  sortConfig: brandsTableSort;
  editText: string;
  editId: number;
  editData: IBrands | undefined;
}
export default defineComponent({
  name: "Brands",
  components: {
    DeleteBrandDialog,
    EditBrandDialog,
    BtnContent,
    AppButton,
    PageMainHeading,
    BrandsTable,
    AppTableController
  },

  setup(): brandsSetup {
    const store = useStore();

    const viewConfiguration: ITableViewConfiguration = reactive({
      page: 1,
      perPage: 10
    });

    watch(viewConfiguration, () => requestBrandsHandler());

    const options = {
      viewConfiguration
    };

    const {
      requestBrandsHandler,
      onDeleteBrandConfirm,
      editBrand,
      createBrand
    } = useSuportedDevices(options);
    requestBrandsHandler();

    function changeViewConfigurationHandler({
      page,
      perPage
    }: ITableViewConfiguration): void {
      if (
        page === viewConfiguration.page &&
        perPage !== viewConfiguration.perPage
      ) {
        viewConfiguration.page = 1;
      } else {
        viewConfiguration.page = page;
      }

      viewConfiguration.perPage = perPage;
    }

    return {
      viewConfiguration,
      brandList: computed(() => store.getters.brandsList),
      totalBrandsCount: computed(() => store.getters.totalBrandsCount),
      requestBrandsHandler,
      onDeleteBrandConfirm,
      editBrand,
      createBrand,
      changeViewConfigurationHandler
    };
  },

  data() {
    return {
      title: "New Brand",
      editText: "",
      editId: 0,
      createBrandDialogOpened: false,
      editBrandDialogOpened: false,
      deleteBrandDialogOpened: false,
      isLoading: false,
      sortConfig: {
        byTitle: { order: sortOrders.asc, keyName: "title" }
      },
      editData: {
        title: "",
        icon: "",
        active: false
      }
    } as brandInitialData;
  },

  computed: {
    emptyText(): string {
      return `No brands found!`;
    }
  },

  methods: {
    async removingBrand(cId: number) {
      this.editId = cId;
      this.deleteBrandDialogOpened = true;
    },
    async submit(data: IBrand) {
      this.isLoading = true;
      let success;
      if (!data.id) {
        success = await this.createBrand(data);
      } else {
        success = await this.editBrand(data);
      }
      if (success) {
        this.editBrandDialogOpened = false;
        await this.requestBrandsHandler();
      }
      this.isLoading = false;
    },
    async onDeleteConfirm() {
      this.isLoading = true;
      const success = await this.onDeleteBrandConfirm(this.editId);
      if (success) {
        this.deleteBrandDialogOpened = false;
        await this.requestBrandsHandler();
      }
      this.isLoading = false;
    },

    async editingBrand(cId: number) {
      this.editId = cId;
      this.editBrandDialogOpened = true;

      this.editData =
        this.brandList.find(categoryItem => categoryItem.id == cId) ||
        undefined;
    },

    onDeleteCancel() {
      this.deleteBrandDialogOpened = false;
    }
  }
});
